@import 'fonts.scss';
@import 'palette.scss';

h1 {
    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 50px;
    line-height: 140%;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;

    color: $paletteMainWhite;
}

h2 {
    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;

    color: $paletteMainWhite;
}

h3 {
    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 25px;
    line-height: 120%;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;

    color: $paletteMainYellow;
}

@mixin mainText {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;

    color: $paletteMainWhite;
}

@mixin footerText {
    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;

    color: $paletteMainWhite;
}

@mixin headerText {
    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;

    color: $paletteMainWhite;
}

@mixin productCard_Name {
    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 400;

    color: $paletteMainBlack;
}

@mixin productCard_Price {
    font-family: 'Neutral Face', 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.05em;

    color: $paletteMainBlack;
    font-weight: bold;
}

@mixin buttonText {
    color: $paletteMainWhite;
    font-size: 20;
    line-height: 120%;
    font-style: normal;
    font-weight: 400;

    &:hover {
        color: $paletteMainBlue;
    }

    &:disabled {
        color: $paletteDisabledGrey;
    }
}

@mixin inputText {
    color: $paletteMainWhite;
    font-style: normal;
    font-weight: 400;

    &::placeholder {
        color: $palettePlaceholderGrey;
    }

    &:disabled {
        color: $paletteDisabledGrey;
    }

    &:invalid {
        color: $paletterMainError;
    }
}
