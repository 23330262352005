@import 'resources.scss';
@import 'fonts.scss';

* {
    @include media(xl) {
        &::-webkit-scrollbar {
            height: 4px;
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: $paletteMainBlack;

            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
    }

    margin: 0;
    padding: 0;

    -webkit-overflow-scrolling: touch;

    box-sizing: border-box;
    scroll-behavior: smooth;

    // mobile tap outline/highlight shit
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
    -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
    outline: none !important;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
}

html {
    font-family: 'Neutral Face', 'Montserrat', sans-serif;
}

body {
    background: $paletteBg;

    &::-webkit-scrollbar {
        width: 10px;

        @media screen and (max-width: 800px) {
            width: 4px;
        }

        background: $paletteMainBlack;
    }

    &::-webkit-scrollbar-thumb {
        background: $paletteMainYellow;
    }

    overflow-x: hidden;
}

a {
    color: $paletteMainYellow;
}

#root {
    background: $paletteBg;
}

.MuiDialog-paper {
    &::-webkit-scrollbar {
        width: 4px;

        @media screen and (max-width: 800px) {
            width: 4px;
        }

        background: $paletteMainBlack;
    }

    &::-webkit-scrollbar-thumb {
        background: $paletteMainWhite;
    }
}
.carousele-small {
    grid-column: 1;

    grid-row: 1 / -1;
    width: 100%;
    height: 100%;
    min-width: 270px;
    cursor: pointer;
}
.carousele-big {
    max-width: 800px;
    max-height: 600px;
    min-width: 400px;
    min-height: 400px;
    width: 100%;
    height: 100%;
}
