/////////////////////////
// MEDIA QUERY MANAGER //
/////////////////////////

@mixin media($breakpoint) {
  @if $breakpoint == xxs {
    @media only screen and (min-width: 320px) {
      @content;
    }
  }
  @if $breakpoint == xs {
    @media only screen and (min-width: 360px) {
      @content;
    }
  }
  @if $breakpoint == sm {
    @media only screen and (min-width: 600px) {
      @content;
    }
  }
  @if $breakpoint == md {
    @media only screen and (min-width: 750px) {
      @content;
    }
  }
  @if $breakpoint == lg {
    @media only screen and (min-width: 1060px) {
      @content;
    }
  }
  @if $breakpoint == xl {
    @media only screen and (min-width: 1240px) {
      @content;
    }
  }
  @if $breakpoint == xxl {
    @media only screen and (min-width: 1440px) {
      @content;
    }
  }
}
